import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'




const MyFooter = () => {
    return (
<footer id="footer" className="container-fluid pt-3">

<div className="row align-items-center justify-content-around ">

<div className="col-12 col-md-auto col-sm-6 footer-name font-weight-bold">
<p>FACES</p>
<p>Friends of America Cultural Exchanges</p>
</div>

<div className="col-12 col-md-auto col-sm-6 d-flex flex-column footer-contact">
  <a href="tel:619-890-7926"> Phone: (619) 890 7926 <FontAwesomeIcon icon={faPhone} /></a>
  <a href="mailto:facejmh@yahoo.com"> E-mail : facejmh@yahoo.com <FontAwesomeIcon icon={faEnvelope} /></a>
</div>
<div className="col-12 col-md-auto col-sm-6 footer-city">
  <p>
    Address : <br/>
    28879 Escalante Road <br/>
    Quail Valley, CA 92587 <br/>
  </p>
</div>
<div className="col-12 col-md-auto col-sm-6 footer-maps"> 
<iframe
  //style="border:0"
  loading="lazy"
  allowfullscreen
  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBpkLEWudfpeuwdGYgT8BkhaOdV25bBcm8&q=28879+Escalante+Road+Quail+Valley+CA+92587">
</iframe>
</div>
</div>
</footer>
    )
}

export default MyFooter