import * as React from 'react'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

const MyHeader = () => {
    return (
<header className="container-fluid">
  <div className="banner">
    <a href="tel:619-890-7926"><span className="disp-hover">(619) 890 7926 </span><FontAwesomeIcon icon={faPhone} /></a>
    <a href="mailto:facejmh@yahoo.com"><span className="disp-hover">facejmh@yahoo.com </span><FontAwesomeIcon icon={faEnvelope} /></a>
  </div>
<div id="header-content" className="header row">
  <div className="col-3 col-md-auto align-middle">
    <Link to="/"><img className="logo zoom anim" src="/logo.svg" alt="logo"/>{' '}</Link>
  </div>
  <div className="col-9 col-md-auto title">Friends of America Cultural Exchanges</div>
</div>
</header>
    )
}

export default MyHeader