import * as React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router';

const MyNavbar = () => {
    let location = useLocation();
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            subitems {
              name
              link
            }
          }
        }
      }
    }
  `)

return(
<>

<Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" sticky="top">
<Link to="/">
  <Navbar.Brand>
  <img
          alt="logo FACES"
          src="/logo-lite.svg"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
    {data.site.siteMetadata.title}
    </Navbar.Brand>
    </Link>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mx-auto">
    <Nav.Link href="/">Home</Nav.Link>
    {data.site.siteMetadata.menuLinks.map(link => (
      <NavDropdown active={myfunc(link.subitems, location)} title={link.name} id="collasible-nav-dropdown" >
        {link.subitems.map(item => (
          <Link data-rr-ui-dropdown-item className="dropdown-item" activeClassName="active" to={item.link}>
            {item.name}</Link>
        ))}
        </NavDropdown>
              ))}
    </Nav>
    <Nav>
      <Nav.Link href="https://app.faces1.com">Log in</Nav.Link>

    </Nav>
  </Navbar.Collapse>
</Navbar>
</>
)
}


export default MyNavbar

function myfunc(items, location){
  let bool = false;
  items.forEach((item) => {
    console.log(location.pathname);
    if (item.link == location.pathname) bool = true;
  })
  return bool;
}