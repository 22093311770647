import * as React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)


const MyBlock = ({ color, bg, title, icon, children }) => {
    return (
        <Row data-aos="fade-up">
            <Col className="block-main col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 mx-auto" style={{ color: color , backgroundColor:  bg }}>
                <div class="block-title">{title} <FontAwesomeIcon icon={["fas", icon]} /></div>
                {children}
            </Col>
        </Row>
    )
}

export default MyBlock