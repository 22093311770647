import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import MyNavbar from './navbar'
import MyHeader from './header'
import MyFooter from './footer'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { container } from './layout.module.css'

const Layout = ({pageTitle, children }) => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`)
  return (
  <div>
  <div id="background_wrap"></div>
  <MyHeader /> 
  <MyNavbar/>
    <Container>
        {children}
    </Container>
    <MyFooter />
  </div>
  )
}

export default Layout